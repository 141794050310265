import { useEffect, useState } from 'react';

import { PlayIcon } from '@heroicons/react/16/solid';
import { SortDirection } from 'ag-grid-community';

import { TextV2 } from '@layr-labs/eigen-kit/react';
import { cn } from '@layr-labs/eigen-kit/util';

// Note: When using a custom header component with ag-grid default sorting is unavailable and must be implemented manually
function HeaderWithSort({
  props,
  children,
  className,
}: {
  props;
  children: React.ReactNode;
  className?: string;
}) {
  const [sortState, setSortState] = useState<SortDirection | null>(props.column.getSort());

  useEffect(() => {
    const sortChangedListener = () => {
      setSortState(props.column.getSort());
    };

    props.column.addEventListener('sortChanged', sortChangedListener);

    return () => {
      props.column.removeEventListener('sortChanged', sortChangedListener);
    };
  }, [props.column, setSortState]);

  const onSortRequested = () => {
    let newSortOrder;

    if (sortState === 'asc') {
      newSortOrder = 'desc';
    } else if (sortState === 'desc') {
      newSortOrder = null;
    } else {
      newSortOrder = 'asc';
    }

    props.setSort(newSortOrder);
  };

  return (
    <div className={cn(className, 'flex items-center')} onClick={onSortRequested}>
      {children}
      <div className="flex flex-col text-blue-400">
        <PlayIcon
          className={cn(
            'h-1.5 w-3 -rotate-90 cursor-pointer text-blue-400 opacity-50 transition-transform duration-200 [&>path]:stroke-[3]',
            sortState === 'asc' && 'opacity-100',
          )}
        />
        <PlayIcon
          className={cn(
            'h-1.5 w-3 rotate-90 cursor-pointer text-blue-400 opacity-50 transition-transform duration-200 [&>path]:stroke-[3]',
            sortState === 'desc' && 'opacity-100',
          )}
        />
      </div>
    </div>
  );
}

export function HeaderWithSortIcon({ displayName, ...props }) {
  return (
    <HeaderWithSort props={props} className="ag-header-cell-label flex items-center gap-2">
      <TextV2
        intent="TextXS"
        className="text-left font-ibmPlexMono font-normal uppercase text-blue-400"
      >
        {displayName}
      </TextV2>
    </HeaderWithSort>
  );
}

export default HeaderWithSort;
