import { Address } from 'abitype';
import { create } from 'zustand';

import { StoreTransaction, TokenSelection, TransactionStatus } from '@layr-labs/eigen-kit/types';

import { NEW_STORE_TRANSACTION } from '@/utils/constants';

export const BUTTON_CONTAINER_ID = 'restake-flow-button-container';

export enum RestakeStep {
  deposit = 'deposit',
  delegate = 'delegate',
}

interface Transaction extends StoreTransaction {
  amount: number | null;
  bigintAmount: bigint | null;
  strategyAddress?: Address | null;
}

const NEW_TRANSACTION: Transaction = {
  ...NEW_STORE_TRANSACTION,
  amount: null,
  bigintAmount: null,
  strategyAddress: null,
};

interface DelegationTransaction extends StoreTransaction {
  operatorAddress: Address | null;
  operatorName: string | null;
  apr?: number | null;
  estimatedRewards?: TokenSelection[] | null;
}

const NEW_DELEGATION_TRANSACTION: DelegationTransaction = {
  ...NEW_STORE_TRANSACTION,
  operatorAddress: null,
  operatorName: null,
  apr: null,
  estimatedRewards: null,
};

type RestakeFlowState = {
  transaction: Transaction;
  updateTransaction: (updatedTxn: Partial<Transaction>) => void;
  resetTransaction: () => void;
  delegationTransaction: DelegationTransaction;
  updateDelegationTransaction: (updatedTxn: Partial<DelegationTransaction>) => void;
  resetDelegationTransaction: () => void;
  approvalTransaction: StoreTransaction;
  updateApprovalTransaction: (updatedTxn: Partial<StoreTransaction>) => void;
  resetAll: () => void;
  step: RestakeStep;
  setStep: (step: RestakeStep) => void;
  resetFailedTransactions: () => void;
  isRestakeModalOpen: boolean;
  setIsRestakeModalOpen: (isOpen: boolean) => void;
};

const useRestakeFlowStore = create<RestakeFlowState>((set) => ({
  transaction: NEW_TRANSACTION,
  updateTransaction: (updatedTxn) =>
    set((state) => ({
      transaction: { ...state.transaction, ...updatedTxn },
    })),
  resetTransaction: () => set({ transaction: NEW_TRANSACTION, step: RestakeStep.deposit }),
  delegationTransaction: NEW_DELEGATION_TRANSACTION,
  updateDelegationTransaction: (updatedTxn) =>
    set((state) => ({
      delegationTransaction: { ...state.delegationTransaction, ...updatedTxn },
    })),
  resetDelegationTransaction: () => set({ delegationTransaction: NEW_DELEGATION_TRANSACTION }),
  approvalTransaction: NEW_STORE_TRANSACTION,
  updateApprovalTransaction: (updatedTxn) =>
    set((state) => ({
      approvalTransaction: { ...state.approvalTransaction, ...updatedTxn },
    })),
  resetAll: () =>
    set({
      transaction: NEW_TRANSACTION,
      delegationTransaction: NEW_DELEGATION_TRANSACTION,
      approvalTransaction: NEW_STORE_TRANSACTION,
      step: RestakeStep.deposit,
    }),
  step: RestakeStep.deposit,
  setStep: (step) => set({ step }),
  resetFailedTransactions: () =>
    set((state) => ({
      transaction: {
        ...state.transaction,
        status:
          state.transaction.status === TransactionStatus.Failed
            ? TransactionStatus.NotStarted
            : state.transaction.status,
      },
      delegationTransaction: {
        ...state.delegationTransaction,
        status:
          state.delegationTransaction.status === TransactionStatus.Failed
            ? TransactionStatus.NotStarted
            : state.delegationTransaction.status,
      },
      approvalTransaction: {
        ...state.approvalTransaction,
        status:
          state.approvalTransaction.status === TransactionStatus.Failed
            ? TransactionStatus.NotStarted
            : state.approvalTransaction.status,
      },
    })),
  isRestakeModalOpen: false,
  setIsRestakeModalOpen: (isOpen) => set({ isRestakeModalOpen: isOpen }),
}));

export default useRestakeFlowStore;
