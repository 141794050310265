import { CSSProperties } from 'react';
import Image from 'next/image';

import { ComponentSize, TextV2 } from '@layr-labs/eigen-kit/react';

interface TokenIconProps {
  src: string;
  size?: Exclude<ComponentSize, ComponentSize.XL>;
  className?: string;
  fill?: boolean;
  priority?: boolean;
  style?: CSSProperties;
  symbol?: string;
}

const sizeToPx = {
  [ComponentSize.XS]: 24,
  [ComponentSize.SM]: 32,
  [ComponentSize.MD]: 48,
  [ComponentSize.LG]: 60,
};

const sizeToClass = {
  [ComponentSize.XS]: 'w-6 h-6',
  [ComponentSize.SM]: 'w-8 h-8',
  [ComponentSize.MD]: 'w-12 h-12',
  [ComponentSize.LG]: 'w-16 h-16',
};

const sizeToTextPX = {
  [ComponentSize.XS]: 'text-[6px]',
  [ComponentSize.SM]: 'text-[8px]',
  [ComponentSize.MD]: 'text-[12px]',
  [ComponentSize.LG]: 'text-[16px]',
};

function TokenIcon({
  src,
  size = ComponentSize.MD,
  symbol,
  className,
  fill,
  priority = false,
  style,
}: TokenIconProps) {
  const dimensions = fill ? undefined : sizeToPx[size];

  if (!src) {
    return (
      <div
        className={`flex shrink-0 items-center justify-center rounded-full border-2 border-gray-300 bg-white ${sizeToClass[size]}`}
      >
        <TextV2 className={`overflow-hidden text-blue-800 ${sizeToTextPX[size]}`}>
          {symbol?.slice(0, 4)}
        </TextV2>
      </div>
    );
  }

  return (
    <Image
      src={src.includes('https://') ? src : `/${src}`}
      width={dimensions}
      height={dimensions}
      alt={src}
      className={className}
      fill={fill}
      priority={priority}
      style={style}
    />
  );
}

export default TokenIcon;
