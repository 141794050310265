import { useCallback } from 'react';

import { Address } from 'abitype';
import { toHex } from 'viem';
import {
  useReadContract,
  useReadContracts,
  useSimulateContract,
  useWaitForTransactionReceipt,
} from 'wagmi';

import { AddressDisplay } from '@layr-labs/eigen-kit/react';
import { IDelegationManagerAbi } from '@layr-labs/eigen-kit/abi';
import { sendTxnDataToCypress, transactionEvent } from '@layr-labs/eigen-kit/util';
import { TransactionLifecycle, TransactionStatus } from '@layr-labs/eigen-kit/types';

import useRestakeFlowStore from 'components/RestakeFlow/useRestakeFlowStore';

import useAccount from '@/hooks/useAccount';
import useFetchLastDelegationTimestamp from '@/hooks/useFetchLastDelegationTimestamp';
import useFetchTokenSharesStrats from '@/hooks/useFetchTokenSharesStrats';

import { stakeConfig } from '@/config';
import { api } from '@/utils/api';
import { ZERO_ADDRESS } from '@/utils/constants';
import { useGetAllRedelegationWithdrawals } from '@/utils/uncompletedWithdrawals';

import { useInvalidateOnBlockNumber } from '../useOnBlockNumber';
import useWriteContractWithToast from './useWriteContractWithToast';

export type ToastSetOpen = (boolean) => void;

const DELEGATION_MANAGER_ADDRESS = stakeConfig.delegationManagerAddress as `0x${string}`;
const DELEGATION_MANAGER_CONTRACT = {
  address: DELEGATION_MANAGER_ADDRESS,
  abi: IDelegationManagerAbi,
};

export type WithdrawalArg = {
  strategyAddresses: `0x${string}`[];
  shares: bigint[];
}[];

// **READ**

// returns address of operator user is delegated to.  if user is not delegated, returns zero address
export const useDelegatedTo = () => {
  const { address, isConnected } = useAccount();
  const result = useReadContract({
    ...DELEGATION_MANAGER_CONTRACT,
    functionName: 'delegatedTo',
    args: address && [address],
    query: {
      enabled: !!address,
    },
  });
  useInvalidateOnBlockNumber({ queryKey: result.queryKey });

  const { data: delegateAddress, isSuccess } = result;
  const isDelegated =
    isSuccess && isConnected && delegateAddress !== ZERO_ADDRESS && delegateAddress !== undefined;

  return { ...result, data: delegateAddress, isDelegated };
};

export const useStakerOptOutWindowBlocks = (operatorAddress: `0x${string}`) => {
  const result = useReadContract({
    ...DELEGATION_MANAGER_CONTRACT,
    functionName: 'stakerOptOutWindowBlocks',
    args: operatorAddress && [operatorAddress],
  });
  return result;
};

export const useDelegationApprover = (operatorAddress: `0x${string}`) => {
  const result = useReadContract({
    ...DELEGATION_MANAGER_CONTRACT,
    functionName: 'delegationApprover',
    args: operatorAddress && [operatorAddress],
  });
  return result;
};

export const useOperatorData = (operatorAddress: `0x${string}`) => {
  const result = useReadContracts({
    contracts: [
      {
        ...DELEGATION_MANAGER_CONTRACT,
        functionName: 'stakerOptOutWindowBlocks',
        args: operatorAddress && [operatorAddress],
      },
      {
        ...DELEGATION_MANAGER_CONTRACT,
        functionName: 'delegationApprover',
        args: operatorAddress && [operatorAddress],
      },
    ],
  });
  return {
    stakerOptOutWindowBlocks: result?.data?.[0] || null,
    delegationApprover: result?.data?.[1] || null,
    hasDelegationApprover: result?.data?.[1]?.result !== ZERO_ADDRESS,
  };
};

// **WRITE**

interface UseDelegateToProps extends TransactionLifecycle {
  operatorAddress?: Address;
  isDelegated: boolean;
  name?: string;
  refetchUndelegatePrepare?: () => void;
  disableToast?: boolean;
}

export const useDelegateTo = ({
  operatorAddress,
  isDelegated,
  name,
  refetchUndelegatePrepare,
  onTxnSuccess,
  disableToast = false,
  onSuccess,
  onError,
  onMutate,
}: UseDelegateToProps) => {
  const { isConnected, address } = useAccount();
  const updateDelegationTransaction = useRestakeFlowStore(
    (state) => state.updateDelegationTransaction,
  );

  const { refetch: refetchTokenSharesStrats } = useFetchTokenSharesStrats();
  const { refetch: refetchDelegatedTo } = useDelegatedTo();
  const { refetch: refetchFetchTokeSharesStrats } = useFetchTokenSharesStrats();
  const { refetch: refetchOperator } = api.operator.getOperatorSummary.useQuery(
    { address: operatorAddress?.toLowerCase() },
    { enabled: !!operatorAddress },
  );

  const { refetch: refetchGetTotalRedelegationWithdrawalsForWithdrawer } =
    useGetAllRedelegationWithdrawals({
      enabled: !!address,
    });

  const { refetchLastDelegationTimestamp } = useFetchLastDelegationTimestamp(address as Address);

  const displayName = name || <AddressDisplay address={operatorAddress} /> || 'operator';

  const prepareContract = useSimulateContract({
    ...DELEGATION_MANAGER_CONTRACT,
    functionName: 'delegateTo',
    args: [
      // zero address for typescript
      operatorAddress ?? ZERO_ADDRESS,
      { signature: '' as `0x${string}`, expiry: 0n },
      toHex('', { size: 32 }),
    ],
    query: {
      enabled: !isDelegated && isConnected && !!operatorAddress,
    },
  });

  const {
    writeContract,
    data: delegateToData,
    isPending: isDelegateToLoading,
    isSuccess: isDelegateToSuccess,
    isError: isDelegateToError,
  } = useWriteContractWithToast({
    successMsg: `Delegated to ${displayName}!`,
    loadingMsg: `Delegating to ${displayName}...`,
    errorMsg: `Delegation submitted.`,
    onTxnSettled: () => {
      refetchUndelegatePrepare?.();
    },
    onTxnSuccess: () => {
      onTxnSuccess?.();
      sendTxnDataToCypress(transactionEvent.delegate, 'success');
      refetchOperator();
      refetchTokenSharesStrats();
      refetchDelegatedTo();
      refetchFetchTokeSharesStrats();
      refetchGetTotalRedelegationWithdrawalsForWithdrawer();
      refetchLastDelegationTimestamp();
      updateDelegationTransaction({ status: TransactionStatus.Success });
    },
    onMutate: () => {
      onMutate?.();
      updateDelegationTransaction({ status: TransactionStatus.Pending });
    },
    onSuccess: (hash) => {
      onSuccess?.();
      updateDelegationTransaction({ hash });
    },
    onError: () => {
      onError?.();
      updateDelegationTransaction({ status: TransactionStatus.Failed });
    },
    disableToast,
  });

  const delegateTo = useCallback(() => {
    prepareContract.data?.request && writeContract?.(prepareContract.data.request);
  }, [prepareContract?.data?.request, writeContract]);

  const {
    data: delegateToTxnData,
    isLoading: isDelegateToTxnLoading,
    isSuccess: isDelegateToTxnSuccess,
    isError: isDelegateToTxnError,
  } = useWaitForTransactionReceipt({ hash: delegateToData });

  return {
    delegateTo: delegateTo,
    delegateToData,
    isDelegateToLoading,
    isDelegateToSuccess,
    isDelegateToError,
    delegateToTxnData,
    isDelegateToTxnLoading,
    isDelegateToTxnSuccess,
    isDelegateToTxnError,
    isDelegateToPrepareError: prepareContract.isError,
    writeContract,
  };
};
