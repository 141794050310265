import { useMemo } from 'react';

import { Address } from 'viem';

import { lastDelegationTimestamp } from '@layr-labs/eigen-kit/types';

import { api } from '@/utils/api';

function useFetchLastDelegationTimestamp(earner?: Address) {
  const {
    data,
    error: lastDelegationTimestampError,
    refetch: refetchLastDelegationTimestamp,
  } = api.rewards.getLastDelegationTimestamp.useQuery<lastDelegationTimestamp>(
    {
      earner: earner ?? '',
    },
    {
      enabled: !!earner,
    },
  );

  const lastDelegationTimestamp = useMemo(() => {
    if (data?.blockTimestamp) {
      return data.blockTimestamp;
    }
    return 0;
  }, [data]);

  const isRewardsPending = useMemo(() => {
    return Date.now() / 1000 - lastDelegationTimestamp < 48 * 60 * 60;
  }, [lastDelegationTimestamp]);

  return {
    lastDelegationTimestamp,
    isRewardsPending,
    lastDelegationTimestampError,
    refetchLastDelegationTimestamp,
  };
}

export default useFetchLastDelegationTimestamp;
